.circular-chart {
  display: block;
  margin: 10px auto;
  max-width: 15%;
  padding-top: 5px;
}

.circle {
  stroke: #6c4e9d;
  fill: none;
  stroke-width: 4;
  stroke-linecap: round;
  /* animation: progress 1s ease-out forwards; */
}

/* @keyframes progress {
  0% {
    stroke-dasharray: 0 100;
  }
} */