.timerBox {
  height: 5px;
  width: 80%;
  margin: 0 auto;
  background: #ccc;
  border-radius: 30px;
}

.totalTimerTop {
  height: 100%;
  width: 100%;
  background: yellow;
  border-radius: 30px;
}

.progressBox {
  height: 2dvh;
  width: 100%;
  margin: 0 auto;
  /* background: #ccc; */
  /* border-radius: 30px;
  border: 2px solid white; */
}

.progressTop {
  height: 100%;
  width: 0%;
  background: #fff;
  border-radius: 30px;
  transition: width .2s;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
}


.timerLinear {
  height: 1dvh;
  width: 100%;
  position: absolute;
  max-height: 24px;
  /* right: -30%; */
  bottom: 0px;
}

.newTimerBox {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: black;
  font-weight: 900;
}



.Score-div {
  width: 80%;
  margin: 0 auto;
  min-width: 160px;
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.tickImg {
  height: 20px;
}

.questionField {
  color: #f5f5f5;
  width: 100%;
  margin: 0 auto;
  font-size: 3dvh;
  text-align: left;
  font-weight: 600;
  width: 80%;
  height: 22dvh;
  padding-bottom: 10px;
  /* background: #ff000055; */
  display: flex;
  align-items: center;
  margin-top: 15px;
  filter: drop-shadow(1px 3px 0px #602DA0);
  text-shadow: 
    -1px -1px 0 #0066FF,  
    1px -1px 0 #0066FF,
    -1px 1px 0 #0066FF,
    1px 1px 0 #0066FF;
}

.questionFieldWrapper {
  position: absolute;
  /* box-shadow: rgb(49, 53, 55) 0px 4px 5px; */
  width: 80%;
  height: 80%;
  /* height: 50vh; */
  /* bottom: 0; */
  display: flex;
  align-items: center;
}

.questionFieldOuter {
  position: absolute;
  /* box-shadow: rgb(49, 53, 55) 0px 4px 5px; */
  width: 75%;
  height: 53dvh;
  bottom: 0;
  display: flex;
  border: 2px solid whitesmoke;
  border-left: none;
  align-items: center;
  border-top-right-radius: 60px;
}

.questionBox {
  width: 100%;
  margin: 0 auto;
  /* background: url('../../../assets/img/v2/gameplayBox3D.png'); */
  background-size: cover;
  padding-bottom: 10px;
  border-radius: 10px;
  min-height: 450px;
  position: absolute;
  bottom: 8dvh;
}

.optionsWrapper {
  width: 85%;
  margin: 0 auto;
  position: absolute;
  right: 0;
  bottom: -5dvh;
  height: 50dvh;
}

.optionsField {
  display: flex;
  flex-direction: column;
  gap: 1dvh;
  justify-content: flex-start;
}



.bg_right {
  background: none;
  background-color: #029602 !important;
}

.bg_wrong {
  background: none;
  background-color: #972727 !important;
}

.singleOptions {
  font-size: 2dvh;
  font-weight: 500;
  text-align: center;
  /* border: 2px solid #ffffff99; */
  border-left: none;
  /* border-radius: 5px; */
  display: flex;
  align-items: center;
  font-weight: 600;
  color: #000;
  background-color: #ffffff15;
  background-image: url('./batMin.png');
  background-size: 120% 100%;
  backdrop-filter: blur(5px);
  min-height: 9dvh;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 10px;
  border-top-left-radius: 3dvh;
  border-bottom-left-radius: 3dvh;
  justify-content: left;
  /* border-radius: 15px; */
}
.singleOptions::after{
  /* display: none; */
  content: '';
  width: min(20dvw,50px);
  height: min(20dvw,50px);
  /* border: min(6dvw,5px) solid white; */
  position: absolute;
  border-radius: 50%;
  left: max(-10dvw,-25px);
  background: url('./ball.png');
  background-size: 100% 100%;
  /* background: linear-gradient(to right bottom, #fff, #3db3ee); */
}

.op{
  width: 80%;
}

.scoreAndTimer{
  width: 80%;
  aspect-ratio: 976/255;
  margin: 0 auto;
  min-width: 160px;
  display: flex;
  margin-top: 5dvh;
  position: relative;
}

.scoreAndTimerImg{
  width: 100%;
  height: 100%;
  position: absolute;
}

.timerDivInImage{
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 5dvh;
  color: #f5f5f5;
  font-weight: 600;
}

.scoreDiv{
  position: absolute;
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
  width: 98%;
  padding-left: 1%;
  font-size: small;
  left: 1%;
  bottom: 3%;
}