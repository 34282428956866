:root {
  --carouselItem-translateZ: 165px;
}

.quizCard4 {
  width: 100%;
  display: flex;
  justify-content: center;
  /* flex-direction: column; */
  margin: 0 auto;
  padding-top: 5dvh;
  position: relative;
}

.carousel_container {
  margin: 0 auto;
  width: 90%;
  /* min-height: 100px; */
  min-height: 12dvh;
  /* background: #00808099; */
  position: relative;
  perspective: 1000px;
  perspective-origin: 50% -25%;
  /* perspective-origin: center; */
  top: 0px;
  /* transform: translate(25%); */
}

.carousel {
  height: 100%;
  width: 100%;
  position: relative;
  transform-style: preserve-3d;
  transition: transform .45s;
  display: flex;
  justify-content: center;
}

.upperbody {
  position: absolute;
  background-color: #ff000099;
  width: 100%;
  height: 100px;
}

.item {
  display: block;
  position: absolute;
  width: 50%;
  font-size: 5em;
  text-align: center;
  color: #FFF;
  opacity: 0.9;
  border-radius: 10px;
  margin: 0 auto;
  cursor: grab;
}

@media screen and (max-width: 380px) {
  :root {
    --carouselItem-translateZ: 145px;
  }
  .item{
    width: 48%;
  }
}
@media screen and (min-width: 410px) {
  :root {
    --carouselItem-translateZ: 175px;
  }
  .item{
    width: 50%;
  }
}

.a {
  transform: rotateY(0deg) translateZ(var(--carouselItem-translateZ));
}

.b {
  transform: rotateY(60deg) translateZ(var(--carouselItem-translateZ));
}

.c {
  transform: rotateY(120deg) translateZ(var(--carouselItem-translateZ));
}

.d {
  transform: rotateY(180deg) translateZ(var(--carouselItem-translateZ));
}

.e {
  transform: rotateY(240deg) translateZ(var(--carouselItem-translateZ));
}

.f {
  transform: rotateY(300deg) translateZ(var(--carouselItem-translateZ));
}
