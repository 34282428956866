.ppm_side_bar {
  position: absolute;
  width: 70%;
  height: 100dvh;
  background: #ffffff77;
  z-index: 99;
  top: 0;
  left: -70%;
  flex-direction: column;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  border-top-right-radius: 46px;
  border-bottom-right-radius: 46px;
  overflow: hidden;
  border: 1px solid #ccc;
}

.sideBarShow {
  animation: showSideBar .3s linear;
  animation-fill-mode: forwards;
}

@keyframes showSideBar {
100%{
    /* transform: rotate(360deg); */
    left: 0%;
    display: block !important;
  }
}

.sideBarHide {
  animation: hideSideBar .3s linear;
  animation-fill-mode: forwards;
}

@keyframes hideSideBar {
  100% {
    /* transform: rotate(360deg); */
    left: -70%;
    /* display: none !important; */
  }
}

.ppm_close_bar {
  position: absolute;
  width: 30%;
  height: 100dvh;
  background: #6c4e9d00;
  z-index: 99;
  top: 0;
  right: 0;
  display: block;
}

.sidebarClose {
  position: absolute;
  right: 6%;
  top: 14px;
  width: 32px;
  cursor: pointer;
  padding: 8px;
  color: #626161;
}

.headerPart {
  width: 100%;
  box-shadow: 0px 4px 5px lightgrey;
  height: 20dvh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding-left: 26px;
}

.sidebarAvatar {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  filter: drop-shadow(-1px 3px 6px #000000bb);
}

.detailBox {
  font-size: 14px;
  color: #342061;
  font-weight: bolder;
}
.menuPart {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #342061;
  gap: 1dvh;
  padding-left: 5%;
}

.singleMenu {
  padding: 15px 15px;
  font-size: 20px;
  font-weight: 700;
}
.sideBarLogout {
  position: absolute;
  bottom: 3dvh;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-left: 10%;
}

.loginBtn {
  background: #342061;
  color: white;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 10px;
  margin-top: 10px;
  border: none;
  filter: drop-shadow(-1px 2px 4px #000000bb);
}

.logoutBtn {
  padding: 5px 20px;
  background: #342061;
  color: white;
  border-radius: 25px;
  filter: drop-shadow(-1px 2px 4px #000000bb);
}