.quiz_navbar {
  height: 5%;
  width: 90%;
  padding: 3% 5%;
  display: flex;
  justify-content: space-between;
  margin: 0px auto;
}

.backBtn {
  height: 26px;
  cursor: pointer;
}

.quizPageBody{
  background: url('../../assets/img/mainBg.png');
  /* background: url('../../assets/img/quizBg.png'); */
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  font-family: anekBangla;
  background-position: bottom;
}