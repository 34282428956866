.contactVis {
  width: 35%;
}
.socialIconBox {
  font-size: xx-large;
}
.loadingSpinner{
  /* height: ; */
  color: #7553c5;
  width: 2.5em;
  height: 2.5em;
}