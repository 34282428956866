.contactVis {
  width: 35%;
}
.socialIconBox {
  font-size: xx-large;
  color: #1600ac !important;
  
}
.socialIconBox > a{
  color: #1600ac !important;
  margin-right: 5px;
}

.contactMobile > a, .contactEmail > a{
  color: #1600ac !important;
  text-decoration: none;
}