:root{
  --translateZ: 170px;
}
.bannerBox{
  width: 100%;
  height: 60dvh;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.carouselBox{
  width: 100%;
  aspect-ratio: 2/1;
  perspective: 20000px;
  transform-style: preserve-3d;
  position: relative;
  transform-origin: center;
}
.singleCard{
  width: 90%;
  position: absolute;
  top: 0;
  left: 5%;
  backdrop-filter: blur(15px);
  border-radius: 8%;
}
.singleCard img{
  width: 100%;
  height: 100%;
}


.card1  { transform: rotateX(  0deg) translateZ(var(--translateZ)); }
.card2  { transform: rotateX( 60deg) translateZ(var(--translateZ)); }
.card3  { transform: rotateX(120deg) translateZ(var(--translateZ)); }
.card4  { transform: rotateX(180deg) translateZ(var(--translateZ)); }
.card5  { transform: rotateX(240deg) translateZ(var(--translateZ)); }
.card6  { transform: rotateX(300deg) translateZ(var(--translateZ)); }