.contactVis {
  width: 35%;
}
.socialIconBox {
  font-size: xx-large;
}
.modalSubsCustom{
  font-size: 18px;
  text-align: center;
  font-weight: 600;
}