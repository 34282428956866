@font-face {
  font-family: 'anekBangla'; /*a name to be used later*/
  src: url('./assets/font/AnekBangla-VariableFont_wdth\,wght.ttf'); /*URL to font*/
}
* {
  user-select: none;
}
.App {
  width: 100%;
  background-color: #ccc;
  height: 100dvh;
  position: relative;
}
.ppm_quiz_body {
  position: relative;
  width: 100dvw;
  height: 100dvh;
  max-width: 430px;
  margin: 0 auto;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
}


/*   aspect-ratio: 350 / 586; */
@media only screen and (min-width: 470px)  {
  .ppm_quiz_body{
    position: relative;
    width: auto;
    height: 100dvh;
    aspect-ratio: 350 / 586;
    margin: 0 auto;
  }
}