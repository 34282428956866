.ppm_navbar {
  height: 9%;
  width: 100%;
  padding: 3% 2% 2% 4%;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding-bottom: 0;
  filter: drop-shadow(-1px 3px 1px #000000bb);
  /* background: #4501a3; */
  background-image: url('./topBar.png');
  background-size: cover;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  margin-bottom: 20px;
}

.toggleBtn {
  height: 60%;
  cursor: pointer;
  filter: drop-shadow(-1px 3px 1px #000000bb);
}

.ppm_logo {
  height: 85%;
  filter: drop-shadow(-1px 3px 1px #000000bb);
}

.ppm_quizard_text{
  color: white;
  font-size: 3.5dvh;
  font-weight: 600;
  filter: drop-shadow(-1px 3px 1px #000000bb);
}