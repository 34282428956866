.carousel-container{
  filter: drop-shadow(-1px 1px 1px #9652f5bb);
}

.react-multi-carousel-item {
  padding-left: 20px;
}

.dotBanner{
  font-size: 32px;
  color: #4501a3;
  text-align: center;
  z-index: 77;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: start;
  font-weight: 900;
  display: none;
}

.dotBanner > span{
  line-height: 0px;
  letter-spacing: 2px; 
}

@media (min-height: 700px) {
  .dotBanner{
    display: flex;
  }
}