.wave-wrapper {
  border-radius: 100%;
  width: 100px;
  height: 100px;
  overflow: hidden;
  box-shadow: 0 0 0 5px white, 0 0 0 10px #00c2ff;
  /* margin: 100px auto; */
  position: absolute;
  transform: scale(.5);
  background: white;
  top: 0px;
}
.progress-title {
  position: absolute;
  top: 63%;
  left: 0;
  width: 100%;
  line-height: 100%;
  font-size: 26px;
  margin-top: -25px;
  z-index: 10;
  text-align: center;
  display: table-cell;
  font-family: arial;
  vertical-align: middle;
  transform-origin: center;
}
.wave {
  width: 1000px;
  height: 500px;
  position: relative;
  filter: contrast(80);
  background: #ffffff;
  overflow: hidden;
  -webkit-animation: wave-animation 4s infinite linear;
          animation: wave-animation 4s infinite linear;
}
.wave::before,
.wave::after {
  content: " ";
  position: absolute;
  width: 250px;
  height: 250px;
  top: 50%;
  margin-top: -125px;
  border-radius: 100%;
  filter: blur(50px);
  background: #00c2ff;
  box-shadow: 500px 0 0 #00c2ff, 0 120px 0 #00c2ff, 500px 120px 0 #00c2ff;
}
.wave::after {
  left: 250px;
}
@-webkit-keyframes wave-animation {
  0% {
    transform: translateX(-10%);
  }
  100% {
    transform: translateX(-60%);
  }
}
@keyframes wave-animation {
  0% {
    transform: translateX(-10%);
  }
  100% {
    transform: translateX(-60%);
  }
}
