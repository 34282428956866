.initialBanner{
  position: absolute;
  width: 100%;
  background: #cccccc66;
  height: 100dvh;
  z-index: 9999;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.openingBannerImg{
  width: 90%;
}

.closeBtn{
  font-size: 20px;
  font-weight: 700;
  color: #5257E1;
  position: absolute;
  right: 3%;
  top: 30dvh;
  border: 1px solid #5257E1;
  border-radius: 50%;
  padding: 0px 8px;
}

.extraHiddenFile{
  position: absolute;
  z-index: -9999999;
}

.whiteBackground{
  height: 100dvh;
  width: 100%;
  background-color: #fff;
  position: absolute;
}