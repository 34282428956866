.ramadanBoxList:last-child{
border-bottom-right-radius: 100px;
}

.bg_wrong_ramadan {
  color: white !important;
  background-image: url('./assets/batWrong.png') !important;
  /* background-color: #029602 !important; */
}

.bg_right_ramadan {
  color: white !important;
  background-image: url('./assets/batRight.png') !important;
  /* background-color: #e93441 !important; */
}