.container {
  display: grid;
  grid-template-columns: 45% 1fr 1fr;
  grid-template-rows: auto auto;
  width: 100%;
  height: 100%;
}
.element {
  padding: 5px;
  filter: drop-shadow(-1px 3px 1px #000000bb);
}
.column1 {
  grid-row: span 2;
}
.column2 {
}
.column3 {
}