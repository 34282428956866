.ppm_bottom_bar {
  position: absolute;
  border-radius: 10px;
  width: 100%;
  bottom: 0px;
  margin-bottom: 6dvh;
  display: flex;
  justify-content: center;
  /* height: 10px; */
}
@media (min-height: 650px) {
  .ppm_bottom_bar {
    margin-bottom: 7dvh;
  }
}

.bottom_nav {
  width: 82%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  filter: drop-shadow(-3px 2px 10px #c0c0c0bb);
}

.bottomImg{
  width: 100%;
}

.leaderboardClick {
  height: 60px;
  width: 70px;
  position: absolute;
  bottom: 10%;
  right: 25%;
  /* background: #ff000077; */
}
.winnerClick {
  height: 60px;
  width: 70px;
  position: absolute;
  bottom: 12%;
  right: 0%;
  /* background: #ff000077; */
}

.homeClick {
  height: 60px;
  width: 70px;
  position: absolute;
  bottom: 10%;
  right: 52%;
  /* background: #ff000077; */

}

.profileClick {
  height: 60px;
  width: 66px;
  position: absolute;
  bottom: 10%;
  left: 3%;
  /* background: #ff000077; */
}

.floatButtonWrapper{
  display: flex;
  width: 55%;
  right: -10%;
  position: absolute;
  /* top: -43px; */
  transform: scale(.5);
  opacity: 0;
  justify-content: end;
  gap: 5px;
  display: none;
}
.singleButton{
  color: #3e019c;
  padding: 5px;
  border-radius: 5px;
  /* background-color: #3e019c; */
  background-color: #fff;
  filter: drop-shadow(0px -1px 5px #cccccc99);
  text-wrap: nowrap;
  font-weight: 600;
  font-size: max(1.8vh,13px);
  
}