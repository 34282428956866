/* .modal-header {
  background: #1600ac;
  color: white;
  border-top-right-radius: 17px;
  border-top-left-radius: 17px;
  text-align: center;
}

.modalContent {
  border-radius: 20px;
  width: 85%;
  margin: 0 auto;
}

.modal-title {
  font-size: 14px;
  text-align: center;
  width: 100%;
}

.modal-dialog {
  max-width: 425px;
} */

.avatarDeck {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 90%;
  margin: 0 auto;
  padding: 2%;
  gap: 5px;
  border: 1px solid #ccc;
  margin-top: 5px;
  border-radius: 12px;
  min-height: 190px;
}
.imgDeck {
  display: flex;
  justify-content: center;
  /* flex-wrap: wrap; */
  width: 90%;
  margin: 0 auto;
  padding: 2%;
  min-height: 190px;
  border: 1px solid #ccc;
  margin-top: 5px;
  border-radius: 12px;
  /* gap: 5px; */
}
.imgBtn,.avtBtn,.uploadImgBtn{
  padding: 5px;
  margin: 2px 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.activeAvtImg{
  background: #1600ac;
  color: white;
}
.avatarSingle {
  height: 69px;
  aspect-ratio: 1/1;
}
.nameLabel{
  font-weight: 700;
  margin-bottom: 5px;
}
.inputNameField{
  width: 82%;
  border: none;
  background: #cccccc44;
  color: #1600ac;
  height: 30px;
  border-radius: 6px;
  text-align: center;
  font-weight: 600;
  outline: 1px solid #1600acaa;
}
.editNameDiv{
  width: 100%;
  text-align: center;
}
.inputPlayerName{
  width: 85%;
  height: 35px;
  border-radius: 10px;
  outline: none;
  border: none;
  background: #ccccccaa;
  text-align: center;
  color: #ff0;
}

.submitUpdate{
  width: 50%;
  border-radius: 20px;
  background: #1600ac;
  color: white;
  margin: 0 auto;
}

.avatarImg{
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.activeAvatar{
  border: 2px solid #1600ac;
  border-radius: 100%;
}