.numberSubmit {
  width: 50%;
  border-radius: 20px;
  background: #1600ac;
  color: white;
  margin: 0 auto;
}
.refundProfileImg{
  border-radius: 100%;
  height: 25vw;
  aspect-ratio: 1/1;
  object-fit: cover;
  max-height: 108px;
}
/* .modal-header {
  background: #1600ac;
  color: white;
  border-top-right-radius: 17px;
  border-top-left-radius: 17px;
  text-align: center;
}

.modalContent {
  border-radius: 20px;
  width: 85%;
  margin: 0 auto;
}

.modal-title {
  font-size: 14px;
  text-align: center;
  width: 100%;
}

.modal-dialog {
  max-width: 425px;
} */
.requiredErrorOutline{
  outline: 1px solid red;
}

.refundTextArea {
  border: 1px solid #1600ac;
  border-radius: 10px;
  width: 100%;
  color: #1600ac;
}

.radioBox {
  display: flex;
  justify-content: space-around;
  width: 80%;
}

.refundSelect {
  width: 165px;
  border: 1px solid #1600ac;
  color: #1600ac;
  height: 30px;
  border-radius: 5px;
}