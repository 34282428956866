.landingPage{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.landingImg{
  width: 100%;
}