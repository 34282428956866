/* .modal-header {
  background: #6c4e9d;
  color: white;
  border-top-right-radius: 17px;
  border-top-left-radius: 17px;
  text-align: center;
} */
.rewardHeader{
  display: flex;
  justify-content: end;
  padding: 10px;
}
.rewardModalContent {
  border-radius: 20px;
  width: 85%;
  margin: 0 auto;
  background: url('../../../assets/rewards/BG.png');
  height: 60dvh;
}

.modal-title {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  width: 100%;
}

.modal-dialog {
  max-width: 425px;
}


.rewardModalBody {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #6c4e9d;
  padding: 0;
}

.modalBodyCustom{
width: 100%;

}

.rewardIcon {
  font-size: 122px;
}

.mySwiper {
  /* width: 100%; */
  width: 100%;
  min-height: 50dvh;
  display: flex !important;
  align-items: center;
  /* padding-top: 50px;
  padding-bottom: 50px; */
}

.rewardSwiperSlide {
  background-position: center;
  background-size: cover;
  width: 70% !important;
  height: 90% !important;
  /* width: 300px;
  height: 300px; */
}

.rewardSwiperSlide img {
  display: block;
  width: 100%;
  height: 100%;
}

.swiper-wrapper{
  height: 90% !important;
}